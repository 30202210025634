<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="flex flex-col min-h-screen relative">
    <AlertBanner class="print:hidden" />
    <AlertContainer class="print:hidden" />
    <AppHeader class="print:hidden" />

    <div id="e2e-content" class="flex-grow">
      <slot></slot>
    </div>
    <ClientOnly>
      <div class="relative bg-brand-grey-600 md:bg-transparent">
        <help-buttons :chat-status="chatFeatureFlag" />
      </div>
      <back-to-top-button v-if="scrolledValue > 100" />
    </ClientOnly>
    <AppFooter class="print:hidden" />
  </div>
</template>
<script setup lang="ts">
import { useSiteConfigStore } from '~/stores/siteConfig'
import { useUiStore } from '~/stores/ui'
import useExternalWidget from '~/composables/useExternalWidget'
import useHelpers from '~/composables/useHelpers'

const siteConfigStore = useSiteConfigStore()
const uiStore = useUiStore()
const myChatWidget = useExternalWidget()
const { sendApplicablePageGtmData } = useHelpers()

onMounted(async () => {
  await sendApplicablePageGtmData()
})

onUpdated(async () => {
  await sendApplicablePageGtmData()
})

await siteConfigStore.fetch()
myChatWidget.configMyChat()

const chatFeatureFlag = computed(() => {
  return siteConfigStore.config.featureFlags?.displayChatIcon
})

const scrolledValue = computed(() => {
  return uiStore.scrolled?.value || 0
})
</script>
